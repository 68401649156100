import React, { useEffect, useState } from "react";
import NavigationItem, { NavItemType } from "./NavigationItem";
import { NAVIGATION_DEMO_2 } from "data/navigation";
import { useHistory } from "react-router-dom";
import { getAllCategories, getDescentCategories } from "services/CategoryService";
import ncNanoId from "utils/ncNanoId";

function Navigation() {

  const history = useHistory();
  const start_as = localStorage.getItem('start_as');
  const [categories, setCategories] = useState<any>();
  const [navigationData, setNaviagationData] = useState<any>();

  useEffect(() => {

    let myMaleCategories: any[] = [];
    let myFemaleCategories: any[] = [];
    let myUnisexCategories: any[] = [];
    const retrieveCategories = async () => {
      let data: any = await getAllCategories();
      console.log(data);

      for (var i in data) {
        var item = data[i];
        let childs: any[] = [];
        let cats: any = await getDescentCategories(item.id);
        for (let cat of cats) {
          childs.push({ id: cat.id, href: "#", name: cat.name })
        }

        myMaleCategories.push({
          id: item.id,
          href: "/#",
          name: item.name,
          association: "Men",
          children: childs,
        });

        myFemaleCategories.push({
          id: item.id,
          href: "/#",
          name: item.name,
          association: "Women",
          children: childs,
        });

        myUnisexCategories.push({
          id: item.id,
          href: "/#",
          name: item.name,
          association: "Unisex",
          children: childs,
        });
      }

      //console.log(myCategories);

      setCategories(myMaleCategories);
    }

    retrieveCategories();

    console.log(myFemaleCategories);

    let navData: any = [
      {
        id: ncNanoId(),
        href: "/#",
        name: "men",
        type: "megaMenu",
        association: "Men",
        children: myMaleCategories,
      },
      {
        id: ncNanoId(),
        href: "/#",
        name: "women",
        type: "megaMenu",
        association: "Women",
        children: myFemaleCategories,
      },
      /* {
        id: ncNanoId(),
        href: "/#",
        name: "Unisex",
        type: "megaMenu",
        association: "Unisex",
        children: myUnisexCategories,
      }, */
    ]

    setNaviagationData(navData);

    console.log(navData);
    console.log(NAVIGATION_DEMO_2);

    if (!localStorage.getItem('start_as')) {
      history.push("/welcome");
    }

  }, []);

  return (
    <ul className="nc-Navigation flex items-center">
      {navigationData?.map((item: any) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
