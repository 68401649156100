import React, { FC, useContext, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import {Helmet} from "react-helmet";
import Input from "shared/Input/Input";
import {Link, useHistory} from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import {AuthContext} from "context/AuthContext";
import loginImg from "images/login.png";
import { useForm, Resolver } from 'react-hook-form';
import { connection, getLoginUrl } from "services/AuthServices";
import NcImage from "shared/NcImage/NcImage";
import CustomAlert from "components/CustomAlert";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

type LoginValues = {
    email: string;
    password: string;
    remember: boolean;
};

const resolver: Resolver<LoginValues> = async (values) => {
    return {
        values: values.email ? values : {},
        errors: !values.email
            ? {
                email: {
                    type: 'required',
                    message: 'This is required.',
                },
            }
            : {},
    };
};

export interface PageLoginProps {
    className?: string;
}

const loginSocials = [
    {
        name: "Continue with Facebook",
        href: "#",
        icon: facebookSvg,
    },
    /*     {
            name: "Continue with Twitter",
            href: "#",
            icon: twitterSvg,
        }, */
    {
        name: "Continue with Google",
        href: "#",
        icon: googleSvg,
    },
];

const PageLogin: FC<PageLoginProps> = ({className = ""}) => {

    const { t } = useTranslation();
    const {connected, user, afterLogin} = useContext(AuthContext);
    const {register, handleSubmit, formState: {errors}} = useForm<LoginValues>({resolver});
    const history = useHistory();
    const [passwordType, setPassWordType] = useState('password');
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [googleLoginUrl, setGoogleLoginUrl] = useState("");
    const [facebookLoginUrl, setFacebookLoginUrl] = useState("");
    const [authorize, setAuthorize] = useState((localStorage.getItem("store_cookie") !== null && localStorage.getItem("store_cookie") !== undefined));

    function printObject(obj: any): void {
        const keys = Object.keys(obj)
        const values = keys.map(key => `${key}: ${Reflect.get(obj, key)}`)
        console.log(values)
    }

    const showAlert = (type: any, message: string) => {
        toast.custom(
            (t) => (
                <CustomAlert
                    show={t.visible}
                    type={type}
                    message={message}
                />
            ),
            {position: "top-right", id: "nc-product-notify", duration: 3000}
        );
    };

    const loginUser = async (data: any) => {
        try {
            setLoading(true);
            const response: any = await connection(data);

            afterLogin();
            printObject(history.location);
            history.push("/");

        } catch (error: any) {
            if (error.status === 403) {
                let email = error.data.message;
                console.log(email);
                history.push({
                        pathname: '/verify-email',
                        state: {email: email}
                    }
                );
            } else {
                showAlert("error", error.data.message)
                console.log(error.data.message);
            }
        }
        setLoading(false);
    };

    const togglePassword = () => {
        setIsOpen(!isOpen)
        if (passwordType === "password") {
            setPassWordType("text");
            keepFocus('password')
        } else {
            setPassWordType("password");
            keepFocus('password')
        }
    }

    const keepFocus = (element: string) => {

        let input = document.getElementById(element);
        input?.focus();
    }

    useEffect(() => {
        const fetchLoginUrl = async () => {

            let googleData: any = await getLoginUrl('google');
            console.log(googleData.provider_redirect)
            let facebookData: any = await getLoginUrl('facebook');
            console.log(facebookData.provider_redirect)

            setFacebookLoginUrl(facebookData.provider_redirect);
            setGoogleLoginUrl(googleData.provider_redirect)

        }
        fetchLoginUrl();
    }, []);

    const notifyErorAddTocart = async () => {
        //console.log(selectedVariant);
        if (!authorize) {
            showAlert("error", "Please authorize cookies before continuing!");
        }
    };

    return (
        <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
            <Helmet>
                <title>Login || DoDo Shop</title>
            </Helmet>
            <div className="container mb-24 lg:mb-32">
                <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                {t("login")}
                </h2>
                <div className="container max-w-7xl mx-auto">
                    <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-12 ">
                        <div className="max-w-sm space-y-8">
                            <NcImage containerClassName="block dark:hidden" src={loginImg}/>
                            <NcImage
                                containerClassName="hidden dark:block"
                                src={loginImg}
                            />
                        </div>
                        <div>
                            <div className="max-w-md mx-auto space-y-6">
                                
                                {/* OR */}
                                {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
                                {/* FORM */}
                                <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(loginUser)}>
                                    <label className="block">
                                        <span className="text-neutral-800 dark:text-neutral-200">
                                        {t("email_address")}
                                        </span>
                                        <Input
                                            type="email"
                                            placeholder="example@example.com"
                                            className="mt-1"
                                            {...register("email")}
                                        />
                                        {errors?.email && <p>{errors.email.message}</p>}
                                    </label>
                                    <label className="block">
                                        <span
                                            className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                                            {t("password")}
                                        </span>
                                        <div style={{position: 'relative'}}>
                                            <Input type={passwordType} className="mt-1" {...register("password")} />

                                        </div>
                                        {!isOpen ?
                                            <img src="/eye-solid.svg" className="myEye" onClick={togglePassword}
                                                 alt="toggle"/>
                                            :
                                            <img src="/eye-slash-solid.svg" className="myEye" onClick={togglePassword}
                                                 alt="toggle"/>
                                        }

                                        {/*                        <span className="text-align-right text-sm text-amber-400">*/}
                                        {/* <input type="checkbox" className="text-sm text-amber-400" onClick={togglePassword} style={{marginLeft:'-15%'}}/>  Show password*/}
                                        {/*</span>*/}
                                    </label>

                                    <label className="block">
                                        <span className="flex justify-between  text-neutral-800 dark:text-neutral-200">

                                            <input type="checkbox" {...register("remember")} /> {t("remember_me")}
                                            <Link to="/forgot-password" className="text-sm text-green-600">
                                            {t("forgot_password")}
                                            </Link>
                                        </span>
                                    </label>

                                    <ButtonPrimary loading={isLoading}
                                                   disabled={!authorize}
                                                   type={authorize ? "submit" : "button"}
                                                   onClick={notifyErorAddTocart}
                                    >{t("login")}</ButtonPrimary>
                                </form>

                                {/* ==== */}
                                <span className="block text-center text-neutral-700 dark:text-neutral-300">
                                {t("new_user")}? {` `}
                                    <Link className="text-green-600" to="/signup">
                                    {t("create_account")}
                                    </Link>
                                </span>

                                <div className="grid gap-3">
                                    {loginSocials.map((item, index) => (
                                        <a
                                            key={index}
                                            href={index == 0 ? facebookLoginUrl : googleLoginUrl}
                                            className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                                        >
                                            <img
                                                className="flex-shrink-0"
                                                src={item.icon}
                                                alt={item.name}
                                            />
                                            <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                                                {item.name}
                                            </h3>
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default PageLogin;
