import { Dialog, Transition } from "@headlessui/react";
import React, { FC, Fragment, useContext } from "react";
import { useLocation } from "react-router-dom";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ProductQuickView from "./ProductQuickView";
import ProductQuickView2 from "./ProductQuickView2";
import { Link } from "react-router-dom";
import Textarea from "shared/Textarea/Textarea";
import Label from "./Label/Label";
import Input from "shared/Input/Input";
import { StarIcon } from "@heroicons/react/24/solid";
import logoNbg from "images/logo_dodo-nbg.png";
import modalImg from "images/undraw_welcome.png";
import BagIcon from "components/BagIcon";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import detail1JPG from "images/products/detail1.jpg";
import detail2JPG from "images/products/detail2.jpg";
import detail3JPG from "images/products/detail3.jpg";
import AccordionInfo from "containers/ProductDetailPage/AccordionInfo";
import whatsapp from "images/socials/whatsapp.svg";
import gmail from "images/socials/gmail.svg";
import { useForm } from "react-hook-form";
import CustomAlert from "./CustomAlert";
import { sendMail } from "services/UserServices";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { AuthContext } from "context/AuthContext";

export interface ModalQuickViewProps {
  show: boolean;
  onCloseModalQuickView: () => void;
}

type MailValues = {
  subject: string;
  email: string;
  phone: string;
  message: string;
};

const HelpModalShow: FC<ModalQuickViewProps> = ({
  show,
  onCloseModalQuickView,
}) => {

  const { t } = useTranslation();
  const { admin } = useContext(AuthContext);
  const { register, handleSubmit, reset, formState: { errors } } = useForm<MailValues>();

  const location = useLocation();
  const LIST_IMAGES_DEMO = [detail1JPG, detail2JPG, detail3JPG];

  const [variantActive, setVariantActive] = React.useState(0);
  const [qualitySelected, setQualitySelected] = React.useState(1);

  const showAlert = (type: any, message: string) => {
    toast.custom(
      (t) => (
        <CustomAlert
          show={t.visible}
          type={type}
          message={message}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };

  const sendMessage = async (data: any) => {
    try {
      const result = await sendMail(data);
      showAlert("success", "Message send Successfully !")
      reset();
      onCloseModalQuickView();
    } catch (error: any) {
      showAlert("error", error.message)
      reset();
      onCloseModalQuickView();
      console.log(error.message);
    }
};

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50"
        onClose={onCloseModalQuickView}
      >
        <div className="flex items-stretch md:items-center justify-center h-full text-center md:px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative inline-flex xl:py-8 w-full max-w-5xl max-h-full">
              <div
                className="flex-1 flex overflow-hidden max-h-full p-8 w-full text-left align-middle transition-all transform lg:rounded-2xl bg-white 
              dark:bg-neutral-900 dark:border dark:border-slate-700 dark:text-slate-100 shadow-xl"
              >
                <span className="absolute right-3 top-3 z-50">
                  <ButtonClose onClick={onCloseModalQuickView} />
                </span>

                <div className="flex-1 overflow-y-auto rounded-xl hiddenScrollbar">
                  <div className={`nc-ProductQuickView`}>
                    {/* MAIn */}
                    <div className="lg:flex">
                      {/* CONTENT */}
                      <div className="w-full lg:w-[50%] ">
                        {/* HEADING */}
                        <div className="relative">
                          <div className="aspect-w-16 aspect-h-16">
                            <img
                              src={modalImg}
                              className="w-full rounded-xl object-cover"
                              alt="product detail 1"
                            />
                          </div>
                        </div>

                      </div>

                      {/* SIDEBAR */}
                      <div className="w-full lg:w-[50%] pt-6 lg:pt-0 lg:pl-7 xl:pl-8">
                        <div className="space-y-8">
                          {/* ---------- 1 HEADING ----------  */}
                          <div>
                            <h2 className="text-2xl font-semibold hover:text-primary-6000 transition-colors">
                              <Link to="#">{t("footer_help")}</Link>
                            </h2>
                            <p>{t("help_text")}</p>
                          </div>
                          <div className="flex space-x-3.5">
                            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
                              <form onSubmit={handleSubmit(sendMessage)}>
                                <div>
                                  <Label>{t("mail_title")}</Label>
                                  <Input className="mt-1.5" {...register("subject")} />
                                </div>
                                <div>
                                  <Label>{t("email")}</Label>
                                  <Input className="mt-1.5" {...register("email")} />
                                </div>
                                <div>
                                  <Label>{t("phone")}</Label>
                                  <Input className="mt-1.5" {...register("phone")} />
                                </div>

                                <div>
                                  <Label>{t("message")}</Label>
                                  <Textarea className="mt-1.5" {...register("message")} />
                                </div>
                                <div className="pt-2">
                                  <ButtonPrimary type="submit">{t("email")}</ButtonPrimary>
                                </div>
                              </form>
                            </div>
                          </div>
                          <hr className=" border-slate-200 dark:border-slate-700"></hr>

                          <div>
                            <p>{t("help_contact")}</p>
                            <div className="flex items-center mb-0.5">
                              <a href={`mailto:${admin?.email}?subject=Subject&body=Message`}
                                target="_blank">
                                <div className="flex-shrink-0 w-5 cursor-pointer">
                                  <img src={gmail} alt="" />
                                </div>
                              </a>
                              <a href={`https://wa.me/${admin?.phone}?text=This is WhatsApp sharing example using link`} target="_blank">
                                <div className="flex-shrink-0 w-5 cursor-pointer ml-3">
                                  <img src={whatsapp} alt="" />
                                </div>
                              </a>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default HelpModalShow;
