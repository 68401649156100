import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";
import facebook from "images/socials/facebook.svg";
import twitter from "images/socials/twitter.svg";
import telegram from "images/socials/telegram.svg";
import youtube from "images/socials/youtube.svg";
import InprogressModal from "components/InprogressModal";

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}

const socialsDemo: SocialType[] = [
  { name: "Facebook", icon: facebook, href: "#" },
  { name: "Twitter", icon: twitter, href: "#" },
  { name: "Youtube", icon: youtube, href: "#" },
  { name: "Telegram", icon: telegram, href: "#" },
];

const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "block w-6 h-6",
  socials = socialsDemo,
}) => {

  const [showInprogressModal, setShowInprogressModal] = React.useState(false);
  
  return (
    <>
      <nav
        className={`nc-SocialsList  cursor-pointer flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
        data-nc-id="SocialsList"
      >
        {socials.map((item, i) => (
          <a
            key={i}
            className={`${itemClass} `}
            href={item.href}
            target="_blank"
            rel="noopener noreferrer"
            title={item.name}
            onClick={() => setShowInprogressModal(true)}
          >
            <img src={item.icon} alt="" />
          </a>
        ))}
      </nav>

      <InprogressModal
        show={showInprogressModal}
        onCloseModalQuickView={() => setShowInprogressModal(false)}
      />
    </>
  );
};

export default SocialsList;
