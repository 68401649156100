import ButtonPrimary from "shared/Button/ButtonPrimary";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import forgotPass from "images/forgot_password.png";
import { Link, useHistory } from "react-router-dom";
import { resent } from "services/AuthServices";
import { Resolver, useForm } from "react-hook-form";
import Input from "shared/Input/Input";
import { forgot } from "services/UserServices";
import { Alert } from "shared/Alert/Alert";
import { useBoolean } from "react-use";
import toast from "react-hot-toast";
import CustomAlert from "components/CustomAlert";

type forgotValues = {
    email: string;
  };
  
  const resolver: Resolver<forgotValues> = async (values) => {
    return {
      values: values.email ? values : {},
      errors: !values.email
        ? {
            email: {
              type: 'required',
              message: 'This is required.',
            },
          }
        : {},
    };
  };

const ForgotPassword: React.FC = () => {

    const history = useHistory();
    const { register, handleSubmit, reset, formState: { errors } } = useForm<forgotValues>({ resolver });
    const [isLoading, setLoading] = useState(false);

    const showAlert = (type: any, message: string) => {
      toast.custom(
        (t) => (
          <CustomAlert
            show={t.visible}
            type={type}
            message={message}
          />
        ),
        { position: "top-right", id: "nc-product-notify", duration: 3000 }
      );
    };

      const forgotPassword = async (data: any) => {
        console.log(data);
        setLoading(true);
        try {
          let result = await forgot(data);
          showAlert("success", "Reset mail send with success !")
          reset();
    
        } catch (error: any) {
          console.log(error.message);
          showAlert("error", error.message)
        }
        setLoading(false);
      };


      return (    
  <div className="nc-Page404">
    <Helmet>
      <title>Forgot Password || DoDo Shop</title>
    </Helmet>
    <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
      {/* HEADER */}
      <header className="text-center max-w-2xl mx-auto space-y-2">
        <NcImage src={forgotPass} />
        <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
          FORGOT PASSWORD ?{" "}
        </span>
        <div className="pt-8">
        <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(forgotPassword)}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                {...register("email")}
              />
              {errors?.email && <p>{errors.email.message}</p>}
            </label>
            <ButtonPrimary loading={isLoading} type="submit">Send Password Reset Link</ButtonPrimary>
          </form>
        </div>
      </header>
      <div className="max-w-md mx-auto space-y-6 mt-3">

          <span className="block text-center text-neutral-700 dark:text-neutral-300">
          Click again to send another if not received.
          </span>
        </div>
    </div>
  </div>
);
}

export default ForgotPassword;
