import React, {createContext, FC, ReactNode, useEffect, useState} from "react";
import {getAdmin, me} from "services/UserServices";
import {useHistory} from "react-router-dom";
import {getCarts} from "../services/ArticleServices";
import { getSelectedCategories } from "services/CategoryService";



export type AuthContextState = {
    connected: boolean;
    user: any;
    admin: any;
    afterLogin: () => void;
    logOut: () => void;
}

const contextDefaultValues: AuthContextState = {
    connected: false,
    user: null,
    admin: null,
    afterLogin: () => {
    },
    logOut: () => {
    },
};

export const AuthContext = createContext<AuthContextState>(
    contextDefaultValues
);

interface Props {
    children?: ReactNode
}

const AuthContextProvider: FC<Props> = ({children}) => {
    const [connected, setConnected] = useState<boolean>(false);
    const [user, setUser] = useState<any>(null);
    const [admin, setAdmin] = useState<any>(null);
    const [myCart, setMyCart] = useState<any>([]);
    const history = useHistory();
    const [selectedCategories, setSelectedCategories] = useState < any > (null);

    useEffect(() => {
        async function fetchUser() {
            if (localStorage.getItem("access_token") || sessionStorage.getItem("access_token")) {
                try {
                    let result: any = await me();
                    setUser(result.data.data);
                    setConnected(true);
                } catch (error: any) {
                }
            }

            try {
                let result: any = await getAdmin();
                setAdmin(result);
            } catch (error: any) {
            }
        }
        fetchUser();

/*         const retrieveSelectedCategories = async () => {
            let data: any = await getSelectedCategories();
            console.log(data);
            let arr: any = Object.values(data);
            console.log(arr);
      
            let myCats: any[] = [];
      
            for (var i in arr) {
              var item = arr[i];
              myCats.push({
                "name" : item.name,
                "desc": item.number_of_articles + " articles",
                "img": item.image,
                "uid": item.category_id
              });
            }
            setSelectedCategories(myCats);
        }

        retrieveSelectedCategories(); */

    }, [connected]);

    const logOut = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user_id');
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('user_id');
        setConnected(false);
        window.location.href = "/"
    };


    const afterLogin = async () => {
        if (localStorage.getItem("access_token") || sessionStorage.getItem("access_token")) {
            try {
                let result: any = await me();
                setUser(result.data.data);
                setConnected(true);
            } catch (error: any) {
                console.log(error.message);
            }
        }
    }

    const afterAddToCard = async () => {
        if (localStorage.getItem("access_token") || sessionStorage.getItem("access_token")) {
            try {
                let data: any = await getCarts(user?.id);
                setMyCart(data);
                console.log("nous somme dans le after after add to card");
            } catch (error: any) {
                console.log(error.message);
            }
        }
    }

    return (
        <AuthContext.Provider
            value={{
                connected,
                user,
                admin,
                afterLogin,
                logOut,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;