import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import DiscoverMoreSlider from "components/DiscoverMoreSlider";
import SectionPromo2 from "components/SectionPromo2";
import { useHistory, useLocation } from "react-router-dom";
import { getArticles, getSelectedArticles } from "services/ArticleServices";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import SectionPromo1 from "components/SectionPromo1";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridMoreExplore from "components/SectionGridMoreExplore/SectionGridMoreExplore";
import SectionSliderLargeProduct from "components/SectionSliderLargeProduct";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import SectionPromo3 from "components/SectionPromo3";
import SectionGridFeatureItems from "./SectionGridFeatureItems";
import Heading from "components/Heading/Heading";
import SectionMagazine5 from "containers/BlogPage/SectionMagazine5";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { getNumberOfArticlesByAss, getSelectedCategories } from "services/CategoryService";
import SectionCategories from "components/SectionCategories";
//import { MEDIA_URL } from "../../constants";

//const MEDIA_URL: string = (process.env.MEDIA_URL as string);

export interface MyCategoryData {
    name: string;
    desc: string;
    img: string;
    uid: number;
}

function PageHome(props: any) {

    const history = useHistory();
    const start_as : string | null = localStorage.getItem('start_as');
    const link_to_redirect : string | null = localStorage.getItem('link_to_redirect')
    const [articles, setArticles] = useState < any > ();
    const [selectedCategories, setSelectedCategories] = useState < any > ();
    const [selectedArticles, setSelectedArticles] = useState < any > ();
    const location = useLocation();

    //const searchParams = new URLSearchParams(location.search);

    useEffect(() => {

        const retrieveArticles = async () => {
            let data: any = await getArticles(start_as);
            let arr: any = Object.values(data);

            let myProducts = [];

            for (var i in arr) {
                var item = arr[i];
                myProducts.push({
                    "id": item.id,
                    "name": item.name,
                    "description": item.description,
                    "price": item.price,
                    "reduction_price": item.reduction_price,
                    "reduction_type": item.reduction_type,
                    "image": item?.medias[0],
                    "category": "Category 1",
                    "tags": ["tag1", "tag2"],
                    "link": "/product-detail-2",
                    "medias": item.medias,
                    // "variants" : "",
                    // "variantType" : "color",
                    "sizes": ["XS", "S", "M", "L", "XL"],
                    "allOfSizes": ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
                    "status": "New in",
                });
            }
            setArticles(myProducts);
        }

        const retrieveSelectedCategories = async () => {
            let data: any = await getSelectedCategories();
            let arr: any = Object.values(data);
      
            let myCats = [];
      
            for (var i in arr) {
              var item = arr[i];

              let number_of_articles: any = await getNumberOfArticlesByAss(item.category_id, start_as);

              console.log(number_of_articles);
                if(number_of_articles >= 1){
                    myCats.push({
                        "name" : item.name,
                        "desc": number_of_articles + " articles",
                        "img": item.image,
                        "uid": item.category_id
                    });
                }

            }
            setSelectedCategories(myCats);
        }    
        
        const retrieveSelectedArticles = async () => {
            let data: any = await getSelectedArticles(start_as);
            let arr: any = Object.values(data);
      
            let myArts = [];
      
            for (var i in arr) {
              var item = arr[i];
              myArts.push({
                "id" : item.id,
                "desc": item.association,
                "images": item.medias,
                "name": item.name,
                "price": item.price, 
                "article_id": item.article_id
              });
            }
      
            setSelectedArticles(myArts);
      
          }
          retrieveSelectedArticles();

        retrieveSelectedCategories();
        if(start_as != null){
            retrieveArticles();
        }
        
        if (!localStorage.getItem('start_as')) {
            history.push("/welcome");
        }

        if(localStorage.getItem('link_to_redirect')){
            localStorage.setItem("pass_through_home", "yes");
            history.push("/reset-password");
        }

    }, []);


    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>Home Page || DoDo Shop</title>
            </Helmet>

            {/* SECTION HERO */}
            <SectionHero2 />

            {/*             <div className="mt-24 lg:mt-32">
                <DiscoverMoreSlider/>
            </div> */}

            <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">
                {/* SECTION */}
                {articles != null ? <SectionSliderProductCard
                    data={articles} /> : null}

                {/*         <div className="py-24 lg:py-32 border-t border-b border-slate-200 dark:border-slate-700">
          <SectionHowItWork />
        </div> */}

                {/* SECTION */}
                {/* <SectionPromo1 /> */}

                {/* SECTION */}
{/*                         <div className="relative py-24 lg:py-32">
          <BackgroundSection />
          <SectionGridMoreExplore />
        </div>  */}

        {selectedCategories != null ? <SectionCategories
                    data={selectedCategories} /> : null}
                {/*  */}
                {/* <SectionPromo2/> */}

                {/* SECTION 3 */}
                {selectedArticles != null ? <SectionSliderLargeProduct cardStyle="style2" data={selectedArticles} />  : null}

                {/* SECTION */}
                {/* <SectionPromo3 /> */}

                {/* SECTION */}
                {/* <SectionGridFeatureItems /> */}

{/*                 <div className="relative py-24 lg:py-32">
                    <BackgroundSection />
                    <div>
                        <Heading rightDescText="From the Ciseco blog">
                            The latest news
                        </Heading>
                        <SectionMagazine5 />
                        <div className="flex mt-16 justify-center">
                            <ButtonSecondary>Show all blog articles</ButtonSecondary>
                        </div>
                    </div>
                </div> */}

                {/*  */}
                {/* <SectionClientSay /> */}
            </div>
        </div>
    );
}

export default PageHome;
