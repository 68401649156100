import React, {useState} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {Page} from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import PageCollection from "containers/PageCollection";
import PageSearch from "containers/PageSearch";
import PageHome2 from "containers/PageHome/PageHome2";
import PageHome3 from "containers/PageHome/PageHome3";
import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
import ProductDetailPage2 from "containers/ProductDetailPage/ProductDetailPage2";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountBilling from "containers/AccountPage/AccountBilling";
import AccountOrder from "containers/AccountPage/AccountOrder";
import CartPage from "containers/ProductDetailPage/CartPage";
import CheckoutPage from "containers/PageCheckout/CheckoutPage";
import PaymentSuccess from "containers/PageCheckout/PaymentSuccess";
import PaymentFailed from "containers/PageCheckout/PaymentFailed";
import PageCollection2 from "containers/PageCollection2";
import LandingPage from "containers/LandingPage/LandingPage";
import PageVerifyEmail from "containers/PageVerifyEmail/PageVerifyEmail";
import PageEmailVerified from "containers/PageEmailVerified/PageEmailVerified";
import ForgotPassword from "containers/ForgotPassword/ForgotPassword";
import ResetPassword from "containers/ResetPassword/ResetPassword";
import InvoicePage from "containers/PageCheckout/InvoicePage";
import Shipping from "../containers/Shipping/Shipping";
import Taxes from "../containers/Taxes/Taxes";
import Impressum from "containers/TermsAndCondition/Impressum";
import Agb from "containers/TermsAndCondition/Agb";
import GoogleCallback from "containers/PageLogin/GoogleCallback";
import FacebookCallback from "containers/PageLogin/FacebookCallback";
import Example from "../components/Example";
import ModalTransferToken from "../components/ModalTransferToken";
import Faq from "containers/TermsAndCondition/Faq";
//import Faq from "containers/TermsAndCondition/Faq";

export const pages: Page[] = [
  //landing page
  { path: "/welcome", exact: true, component: LandingPage },
  //auth pages
  { path: "/verify-email", exact: true, component: PageVerifyEmail },
  { path: "/email-verify-success", exact: true, component: PageEmailVerified },
  { path: "/forgot-password", exact: true, component: ForgotPassword },
  { path: "/reset-password", exact: true, component: ResetPassword },
  { path: "/login-facebook-callback",  exact: true, component: FacebookCallback },
  { path: "/login-google-callback",  exact: true, component: GoogleCallback },

    {path: "/", exact: true, component: PageHome},
    {path: "/#", exact: true, component: PageHome},
    {path: "/home2", exact: true, component: PageHome2},
    {path: "/home3", exact: true, component: PageHome3},
    //
    {path: "/home-header-2", exact: true, component: PageHome},
    {path: "/product-detail", component: ProductDetailPage},
    {path: "/product-detail-2", component: ProductDetailPage2},

    {path: "/page-collection-2", component: PageCollection2},
    {path: "/page-collection", component: PageCollection},
    {path: "/page-search", component: PageSearch},
    //
    {path: "/account", component: AccountPage},
    {path: "/account-savelists", component: AccountSavelists},
    {path: "/account-change-password", component: AccountPass},
    {path: "/account-billing", component: AccountBilling},
    {path: "/account-my-order", component: AccountOrder},
    //
    {path: "/cart", component: CartPage},
    {path: "/checkout", component: CheckoutPage},
    {path: "/invoice", component: InvoicePage},
    {path: "/payment-success", component: PaymentSuccess},
    {path: "/payment-failed", component: PaymentFailed},
    //
    {path: "/blog", component: BlogPage},

  { path: "/blog-single", component: BlogSingle },
  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/agb", component: Agb },
  { path: "/impressum", component: Impressum },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/subscription", component: PageSubcription },
  { path: "/faq", component: Faq },
    //
    {path: "/shipping", component: Shipping},
    {path: "/taxes", component: Taxes},
];

const Routes = () => {
    const [showModal, SetShowModal] = useState(true);
    const close = () => {
        SetShowModal(false);
    }
    return (
        <BrowserRouter basename="/">
            <ScrollToTop/>
            <SiteHeader/>
            {!localStorage.getItem("store_cookie") ?
                <ModalTransferToken show={showModal} onCloseModalTransferToken={close}/>
                : null}

            <Switch>
                {pages.map(({component, path, exact}) => {
                    return (
                        <Route
                            key={path}
                            component={component}
                            exact={!!exact}
                            path={path}/>
                    );
                })}
                <Route component={Page404}/>
            </Switch>
            <Footer/>
        </BrowserRouter>
    );
};

export default Routes;
