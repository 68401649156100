import React, { FC, Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionSliderCollections from "components/SectionSliderLargeProduct";
import SectionPromo1 from "components/SectionPromo1";
import ProductCard from "components/ProductCard";
import TabFilters from "./TabFilters";
import { PRODUCTS } from "data/data";
import { Link } from "react-router-dom";
import { getCategory, getDescentCategories } from "services/CategoryService";
import { getArticlesByCategoryAndAssociation, getArticlesByFilter, getPaginatedArticles } from "services/ArticleServices";
//import { MEDIA_URL } from "../constants";
import { Dialog, Popover, Transition } from "@headlessui/react";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import ReactPaginate from "react-paginate";
import twFocusClass from "utils/twFocusClass";
import env from "ts-react-dotenv";
import { useTranslation } from "react-i18next";

//const MEDIA_URL: string = (process.env.MEDIA_URL as string);
const MEDIA_URL: string = (env.MEDIA_URL as string);

export interface PageCollectionProps {
  className?: string;
}

const PAGINATION_SIZES = [
  {
    name: "25",
    value: 25
  },
  {
    name: "50",
    value: 50
  },
  {
    name: "100",
    value: 100
  },
];


const PageCollection: FC<PageCollectionProps> = ({ className = "" }) => {

  const { t } = useTranslation();
  const [category, setCategory] = useState<any>();
  const [subCategories, setSubCategory] = useState<any>();
  const [association, setAssociation] = useState<string | null>("");
  const [featuresToLoad, setFeaturesToLoad] = useState<any>([]);
  const [articles, setArticles] = useState<any>();
  const [rangePrices, setRangePrices] = useState([0, 5000]);
  const [isCategorySelected, setIsCategorySelected] = useState<boolean>(false);
  const [isAllCategorySelected, setIsAllCategorySelected] = useState<boolean>(false);
  const [keyWord, setKeyWord] = useState<string>("");

  const [sizesState, setSizesState] = useState<string[]>([]);
  const [pageSize, setPageSize] = useState<number>(25);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(0);

  const queryParams = new URLSearchParams(window.location.search);

  let ass = queryParams.get('association');
  let id = queryParams.get('categoryId');

  useEffect(() => {

    const retrieveFeatures = async () => {

      let myCategory: any = await getCategory(id);
      setCategory(myCategory);
      setAssociation(ass);
      let myFeatures = [];
      let mySubCategories = [];

      let cats: any = await getDescentCategories(myCategory.id);

      for (let cat of cats) {
        if (cat.id !== myCategory.id) {
          mySubCategories.push({ id: cat.id, selected: false, name: cat.name });
        }

        const feats = cat.features;
        for (let i = 0; i < feats.length; i++) {
          myFeatures.push({ ...feats[i] });
        }
      }

      setSubCategory(mySubCategories);

      const ids = myFeatures.map(obj => obj.id);
      const uniqueFeatures = myFeatures.filter(({ id }, index) => !ids.includes(id, index + 1));

      let featureArray = [];

      for (var i in uniqueFeatures) {
        var item = uniqueFeatures[i];
        featureArray.push(item);
      }

      let features = [];

      for (let feat of featureArray) {
        let featValues = [];
        for (let featVal of feat.feature_values) {
          featValues.push({ id: featVal.id, selected: false, value: featVal.value })
        }
        features.push({ id: feat.id, name: feat.name, feature_values: featValues, is_selected: false, is_all_selected: false });
      }

      setFeaturesToLoad(features);
    }

    const retrieveArticles = async () => {
      let data: any = await getArticlesByCategoryAndAssociation(id, ass);
      console.log(data);
      let arr: any = Object.values(data);
      console.log(arr);

      let myProducts = [];

      for (var i in arr) {
        var item = arr[i];
        myProducts.push({
          "id": item.id,
          "name": item.name,
          "description": item.description,
          "price": item.price,
          "image": item?.medias[0],
          "category": "Category 1",
          "tags": ["tag1", "tag2"],
          "link": "/product-detail-2",
          "medias": item.medias,
          // "variants" : "",
          // "variantType" : "color",
          "sizes": ["XS", "S", "M", "L", "XL"],
          "allOfSizes": ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
          "status": "New in",
        });
      }

      setArticles(myProducts);
    }

    const retrievePaginatedArticles = async () => {
      let data: any = await getPaginatedArticles(id, ass, 1, 25);
      console.log(data);
      let products = data.data;
      console.log(products);
      setLastPage(data.meta.last_page);
      setCurrentPage(data.meta.current_page);
      setPageSize(data.meta.per_page);

      let myProducts = [];

      for (var i in products) {
        var item = products[i];
        myProducts.push({
          "id": item.id,
          "name": item.name,
          "description": item.description,
          "price": item.price,
          "image": item?.medias[0],
          "category": "Category 1",
          "tags": ["tag1", "tag2"],
          "link": "/product-detail-2",
          "medias": item.medias,
          // "variants" : "",
          // "variantType" : "color",
          "sizes": ["XS", "S", "M", "L", "XL"],
          "allOfSizes": ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
          "status": "New in",
        });
      }

      setArticles(myProducts);
    }

    retrievePaginatedArticles();

    //retrieveArticles();

    retrieveFeatures();
  }, [ass, id]);

  const retrieveArticles = async () => {
    let data: any = await getPaginatedArticles(id, ass, 1, pageSize);
    let products = data.data;
    setLastPage(data.meta.last_page);
    setCurrentPage(data.meta.current_page);
    setPageSize(data.meta.per_page);

    let myProducts = [];

    for (var i in products) {
      var item = products[i];
      myProducts.push({
        "id": item.id,
        "name": item.name,
        "description": item.description,
        "price": item.price,
        "image": item?.medias[0],
        "category": "Category 1",
        "tags": ["tag1", "tag2"],
        "link": "/product-detail-2",
        "medias": item.medias,
        // "variants" : "",
        // "variantType" : "color",
        "sizes": ["XS", "S", "M", "L", "XL"],
        "allOfSizes": ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
        "status": "New in",
      });
    }

    setArticles(myProducts);
  }

  const handleFilter = async () => {

    let cat_ids: number[] = [];
    cat_ids.push(category.id);
    subCategories?.map((item: any) => {
      if (item?.selected) {
        cat_ids.push(item?.id);
      }
    });

    let feature_value_ids: number[] = [];
    featuresToLoad?.map((item: any) => {
      console.log(item?.is_selected);
      if (item?.is_selected) {
        item?.feature_values?.map((elt: any) => {
          console.log(elt?.selected);
          if (elt?.selected) {
            console.log(elt?.id);
            feature_value_ids.push(elt?.id);
          }
        });
      }
    });

    console.log(keyWord, rangePrices, association, cat_ids, feature_value_ids);

    let data: any = await getArticlesByFilter(keyWord, rangePrices, association, cat_ids, feature_value_ids, pageSize);
    let products = data.data;
    setLastPage(data.meta.last_page);
    setCurrentPage(data.meta.current_page);
    setPageSize(data.meta.per_page);

    let myProducts = [];

    for (var i in products) {
      var item = products[i];
      myProducts.push({
        "id": item.id,
        "name": item.name,
        "description": item.description,
        "price": item.price,
        "image": item?.medias[0],
        "category": "Category 1",
        "tags": ["tag1", "tag2"],
        "link": "/product-detail-2",
        "medias": item.medias,
        // "variants" : "",
        // "variantType" : "color",
        "sizes": ["XS", "S", "M", "L", "XL"],
        "allOfSizes": ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
        "status": "New in",
      });
    }

    setArticles(myProducts);
  }

  const handlePageClick = async (val: any) => {
    const requestedPage = val.selected + 1;
    let data: any = await getPaginatedArticles(id, ass, requestedPage, pageSize);
    console.log(data);
    let products = data.data;
    console.log(products);
    setLastPage(data.meta.last_page);
    setCurrentPage(data.meta.current_page);
    setPageSize(data.meta.per_page);

    let myProducts = [];

    for (var i in products) {
      var item = products[i];
      myProducts.push({
        "id": item.id,
        "name": item.name,
        "description": item.description,
        "price": item.price,
        "image": item?.medias[0],
        "category": "Category 1",
        "tags": ["tag1", "tag2"],
        "link": "/product-detail-2",
        "medias": item.medias,
        // "variants" : "",
        // "variantType" : "color",
        "sizes": ["XS", "S", "M", "L", "XL"],
        "allOfSizes": ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
        "status": "New in",
      });
    }
    setArticles(myProducts);
  }

  const changePageSize = async (pagesize: number) => {

    let data: any = await getPaginatedArticles(id, ass, 1, pagesize);
    console.log(data);
    let products = data.data;
    console.log(products);
    setLastPage(data.meta.last_page);
    setCurrentPage(data.meta.current_page);
    setPageSize(data.meta.per_page);

    let myProducts = [];

    for (var i in products) {
      var item = products[i];
      myProducts.push({
        "id": item.id,
        "name": item.name,
        "description": item.description,
        "price": item.price,
        "image": item?.medias[0],
        "category": "Category 1",
        "tags": ["tag1", "tag2"],
        "link": "/product-detail-2",
        "medias": item.medias,
        // "variants" : "",
        // "variantType" : "color",
        "sizes": ["XS", "S", "M", "L", "XL"],
        "allOfSizes": ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
        "status": "New in",
      });
    }
    setArticles(myProducts);
  }


  const updateSubCategories = (id: number) => {

    const newCats = subCategories.map((item: any) => {
      if (item?.id === id) {
        return { id: item?.id, selected: !item?.selected, name: item?.name };
      } else {
        return item;
      }
    });

    setSubCategory(newCats);

    let is_selected_bool = false;
    let is_all_selected_bool = true;

    newCats.map((item: any) => {
      if (item?.selected) {
        is_selected_bool = true;
      } else {
        is_all_selected_bool = false
      }
    });

    setIsCategorySelected(is_selected_bool);
    setIsAllCategorySelected(is_all_selected_bool);
  };

  const clearAllCategories = () => {
    const newCats = subCategories.map((item: any) => {
      return { id: item?.id, selected: false, name: item?.name };
    });

    setIsCategorySelected(false);
    setIsAllCategorySelected(false);
    setSubCategory(newCats);
  };

  const selectAllCategories = () => {
    if (isAllCategorySelected) {
      const newCats = subCategories.map((item: any) => {
        return { id: item?.id, selected: false, name: item?.name };
      });

      setIsCategorySelected(false);
      setIsAllCategorySelected(false);
      setSubCategory(newCats);
    } else {
      const newCats = subCategories.map((item: any) => {
        return { id: item?.id, selected: true, name: item?.name };
      });

      setIsCategorySelected(true);
      setIsAllCategorySelected(true);
      setSubCategory(newCats);
    }
  };

  const selectFeatureValue = (feature_id: number, feature_value_id: number) => {
    const newFeats = featuresToLoad.map((item: any) => {
      if (item.id === feature_id) {
        const newFeatVals = item.feature_values.map((elt: any) => {
          if (elt.id === feature_value_id) {
            return { id: elt.id, selected: !elt.selected, value: elt.value };
          } else {
            return elt;
          }
        });

        return { id: item.id, name: item.name, feature_values: newFeatVals, is_selected: false, is_all_selected: false };
      } else {
        return item;
      }
    });

    const refreshedFeats = newFeats.map((item: any) => {

      let is_selected_bool = false;
      let is_all_selected_bool = true;

      item.feature_values.map((elt: any) => {
        if (elt.selected) {
          is_selected_bool = true;
        } else {
          is_all_selected_bool = false
        }
      });

      return { id: item.id, name: item.name, feature_values: item.feature_values, is_selected: is_selected_bool, is_all_selected: is_all_selected_bool };
    });

    setFeaturesToLoad(refreshedFeats);
  };

  const clearSelectedFeatures = (feature_id: number) => {
    const newFeats = featuresToLoad.map((item: any) => {
      if (item.id === feature_id) {
        const newFeatVals = item.feature_values.map((elt: any) => {
          return { id: elt.id, selected: false, value: elt.value };
        });

        return { id: item.id, name: item.name, feature_values: newFeatVals, is_selected: false, is_all_selected: false };
      } else {
        return item;
      }
    });
    setFeaturesToLoad(newFeats);
  };

  const selectAllFeatureValues = (feature_id: number) => {

    const newFeats = featuresToLoad.map((item: any) => {
      if (item.id === feature_id) {
        if (item.is_selected) {
          const newFeatVals = item.feature_values.map((elt: any) => {
            return { id: elt.id, selected: false, value: elt.value };
          });

          return { id: item.id, name: item.name, feature_values: newFeatVals, is_selected: false, is_all_selected: false };
        } else {
          const newFeatVals = item.feature_values.map((elt: any) => {
            return { id: elt.id, selected: true, value: elt.value };
          });

          return { id: item.id, name: item.name, feature_values: newFeatVals, is_selected: true, is_all_selected: true };
        }
      } else {
        return item;
      }
    });
    setFeaturesToLoad(newFeats);
  };

  const clearAll = () => {
    const newFeats = featuresToLoad.map((item: any) => {
      const newFeatVals = item.feature_values.map((elt: any) => {
        return { id: elt.id, selected: false, value: elt.value };
      });
      return { id: item.id, name: item.name, feature_values: newFeatVals, is_selected: false, is_all_selected: false };
    });
    setFeaturesToLoad(newFeats);

    const newCats = subCategories.map((item: any) => {
      return { id: item?.id, selected: false, name: item?.name };
    });

    setIsCategorySelected(false);
    setIsAllCategorySelected(false);
    setSubCategory(newCats);

    setKeyWord("");
    setRangePrices([1, 5000]);

    retrieveArticles();
  };

  const changePrice = (price_range: number[]) => {
    setRangePrices(price_range);
  };

  const changeKeyWord = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyWord(e.target.value);
  };

  const isSizeSelected = (value: number): boolean => { 
    console.log(value);
    console.log(pageSize);
    if(pageSize == value){ 
      return true; 
    }else{ 
      return false 
    }  
  }

  return (
    <div
      className={`nc-PageCollection ${className}`}
      data-nc-id="PageCollection"
    >
      <Helmet>
        <title>Collection || DoDo Shop</title>
      </Helmet>

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div className="mb-12 sm:mb-16">
            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
              {t("category_page")}
            </h2>
            <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
              <Link to={"/"} className="">
              {t("homepage")}
              </Link>
              <span className="text-xs mx-1 sm:mx-1.5">/</span>
              <Link to={"/#"} className="">
                {association}
              </Link>
              <span className="text-xs mx-1 sm:mx-1.5">/</span>
              <span className="underline">{category?.name}</span>
              {subCategories?.map((item: any, index: number) => (
                <span key={index}>
                  {item?.selected ? <>
                    <span className="text-xs mx-1 sm:mx-1.5">/</span>
                    <span className="underline">{item?.name}</span>
                  </> : ""}
                </span>)
              )}
            </div>
          </div>

          {/*         <hr className="border-slate-200 dark:border-slate-700 my-10 xl:my-12" />
          <div className="max-w-screen-sm">
            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">
              Man collection
            </h2>
            <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
              We not only help you design exceptional products, but also make it
              easy for you to share your designs with more like-minded people.
            </span>
          </div>
 */}
          <hr className="border-slate-200 dark:border-slate-700" />
          <main>
            {/* TABS FILTER */}
            <TabFilters onUpdateSubCategories={updateSubCategories} onSelectFeatureValue={selectFeatureValue} onClearSelectedFeatures={clearSelectedFeatures} onSelectAllFeatureValues={selectAllFeatureValues} onClearAllCategories={clearAllCategories} onSelectAllCategories={selectAllCategories} onSetRangePrice={changePrice} onChangeKeyWord={changeKeyWord} onHandleFilter={handleFilter} onClearAll={clearAll} subCategories={subCategories} isCatSelected={isCategorySelected} isAllCatSelected={isAllCategorySelected} features={featuresToLoad} rangePrices={rangePrices} keyWord={keyWord} />

            {/* LOOP ITEMS */}
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
              {articles?.map((item: any, index: any) => (
                <ProductCard data={item} key={index} />
              ))}
            </div>

            {/* PAGINATION */}
            <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
              <nav
                className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
              >
                <ReactPaginate
                  pageCount={lastPage}
                  initialPage={currentPage - 1}
                  forcePage={currentPage - 1}
                  pageRangeDisplayed={8}
                  marginPagesDisplayed={6}
                  previousLabel="<"
                  nextLabel=">"
                  containerClassName="uk-pagination uk-flex-center"
                  activeClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
                  disabledClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
                  onPageChange={handlePageClick}
                  disableInitialCallback={true}
                />
              </nav>
              <Popover className="relative">
                {({ open, close }) => (
                  <>
                    <Popover.Button
                      className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${!!sizesState.length
                          ? "!border-primary-500 bg-primary-50 text-primary-900"
                          : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                        }
                `}
                    >
                      <svg
                        className="w-4 h-4"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21 9V3H15"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3 15V21H9"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M21 3L13.5 10.5"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.5 13.5L3 21"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <span className="ml-2">{pageSize}</span>

                      <ChevronDownIcon className="w-4 h-4 ml-3" />

                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-40 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                        <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                          <div className="relative flex flex-col px-5 py-6 space-y-5">
                            {PAGINATION_SIZES.map((item) => (
                              <div key={item.name} className="">
                                <Checkbox
                                  name={item.name}
                                  label={item.name}
                                  defaultChecked={isSizeSelected(item.value)}
                                  onChange={() => { changePageSize(item.value) }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

            </div>
          </main>
        </div>

        {/* === SECTION 5 === */}
        {/*         <hr className="border-slate-200 dark:border-slate-700" />

        <SectionSliderCollections />
        <hr className="border-slate-200 dark:border-slate-700" /> */}

        {/* SUBCRIBES */}
        {/* <SectionPromo1 /> */}
      </div>
    </div>
  );
};

export default PageCollection;
