import axios from "axios";
//import * as FormData from 'form-data';
import { request } from 'http';
import { createReadStream } from 'fs';

export const me = () => {

    return new Promise((resolve, reject) => {
      axios
        .get('/me')
        .then( (response: any) => {
          if(response.data){

          resolve(response);
          }else {
            throw new Error('USER NOT FOUND');
          }
        })
        .catch((error: any) => {
          reject(error.response.data);
        });
    });
  };

  export const forgot = (data: any) => {
     let emailFormData = new FormData();

    emailFormData.append("email", data.email);

    return new Promise((resolve, reject) => {
      axios
        .post('/forgetpassword', emailFormData)
        .then( (response: any) => {
          resolve(response);
        })
        .catch( (error: any) => {
          reject(error.response.data);
        });
      });
  };

  export const resetP = (data: any) => {

     let resetFormData = new FormData();

    resetFormData.append("email", data.email);
    resetFormData.append("password", data.password);
    resetFormData.append("password_confirmation", data.password_confirmation);
    resetFormData.append("token", data.token);

    return new Promise((resolve, reject) => {
      axios
        .post('/resetpassword', resetFormData)
        .then( (response: any) => {

          resolve(response);
        })
        .catch( (error: any) => {
          reject(error.response.data);
        });
    });
  };

  export function updateInfos(id: number, data: any, gender: string) {
    console.log(gender);
    return new Promise((resolve, reject) => {
        axios
            .post(`/update/${id}`, data)
            .then( (response: any) => {
                resolve(response.data);
            })
            .catch((error: any) => {
                reject(error.response.data);
            });
    });

};

export function updateAvatar(id: number, image: any) {
  const formData = new FormData();
  //const blob = new Blob([image]);
  //let url = URL.createObjectURL(image);
  //const readStream = createReadStream(url);
  formData.append("avatar", image, "filename.jpg");
  formData.append("test", "test");
  Object.keys(formData).forEach(key => console.log(key));
  console.log(formData);
  console.log(image);

  return axios({
    method: 'post',
    url: '/admins/'+`${id}`+'/avatar',
    data: formData,
    headers: {'Content-Type': 'multipart/form-data' }
    })
    .then(function (response) {
        //handle success
    })
    .catch(function (response) {
        //handle error
    });

/*   return new Promise((resolve, reject) => {
    axios
        .post('/admins/'+`${id}`+'/avatar', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then( (response : any)=> {
            resolve(response.data.data);
        })
        .catch((error: any) => {
            reject(error.response.data);
        });
}); */

};

export function changePassword(id: number, data: any) {
  let formData = new FormData();

  return new Promise((resolve, reject) => {
      axios
          .post(`/user/${id}/change/password`, data)
          .then( (response: any) => {
              resolve(response.data.data);
          })
          .catch((error: any) => {
              reject(error.response.data);
          });
  });

};

export function getAllPurchases(id:any, pageId:any, nb_of_element:any) {

    const data = {numberElement: nb_of_element}

    if(pageId !== undefined && pageId !== null){
        return new Promise((resolve, reject) => {
            axios

                .post(`/user/${id}/purchases?page=${pageId}`, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        }); 
    }else {
        return new Promise((resolve, reject) => {
            axios
                .post(`/user/${id}/purchases`, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

};

export const getAllFaqs = () => {

  return new Promise((resolve, reject) => {
    axios
      .get('/get/faqs/type/FAQ')
      .then( (response: any) => {

        resolve(response.data.data);

      })
      .catch((error: any) => {
        reject(error.response.data);
      });
  });
};

export const getAllAgbs = () => {

  return new Promise((resolve, reject) => {
    axios
      .get('/get/faqs/type/AGB')
      .then( (response: any) => {

        resolve(response.data.data);

      })
      .catch((error: any) => {
        reject(error.response.data);
      });
  });
};

export function sendMail(data: any) {
console.log(data)
  return new Promise((resolve, reject) => {
      axios
          .post(`/send/user/mail`, data)
          .then( (response: any) => {
              resolve(response.data.data);
          })
          .catch((error: any) => {
              reject(error.response.data);
          });
  });

};

export function removeAvatarRequest(id:any, image:any) {

  return new Promise((resolve, reject) => {
      axios
          .put('/user/'+`${id}`+'/avatar/remove', image)
          .then( response => {
              resolve(response.data.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
  });

};

export const getAdmin = () => {

  return new Promise((resolve, reject) => {
    axios
      .get('/admin/contact')
      .then( (response: any) => {

        resolve(response.data.data);

      })
      .catch((error: any) => {
        reject(error.response.data);
      });
  });
};