import React, {FC, useContext, useEffect, useState} from "react";
import {
    NoSymbolIcon,
    ClockIcon,
    SparklesIcon, PlusIcon, MinusIcon,
} from "@heroicons/react/24/outline";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import ModalPhotos from "./ModalPhotos";
import ReviewItem from "components/ReviewItem";
import {PRODUCTS, ProductVariant} from "data/data";
import IconDiscount from "components/IconDiscount";
import NcInputNumber from "components/NcInputNumber";
import BagIcon from "components/BagIcon";
import AccordionInfo from "./AccordionInfo";
import Policy from "./Policy";
import toast from "react-hot-toast";
import {StarIcon} from "@heroicons/react/24/solid";
import ModalViewAllReviews from "./ModalViewAllReviews";
import NotifyAddTocart from "components/NotifyAddTocart";
import {addToCart, getOneArticle} from "../../services/ArticleServices";
//import {MEDIA_URL} from "../../constants";
import {Disclosure} from "@headlessui/react";
import productVariantImg6 from "../../images/products/v6.jpg";
import {productImgs} from "../../contains/fakeData";
import productVariantImg2 from "../../images/products/v2.jpg";
import CustomAlert from "components/CustomAlert";
import {AuthContext} from "context/AuthContext";
import {CartContext} from "../../context/CartContext";
import Prices from "../../components/Prices";
import Price2 from "../../components/Price2";
import ReactLoading from "react-loading";
import env from "ts-react-dotenv";
import {computePrice, reductionAmount} from "../../constants";
import whatsapp from "images/socials/whatsapp.svg";
import gmail from "images/socials/gmail.svg";
import { useTranslation } from "react-i18next";

//const MEDIA_URL: string = (process.env.MEDIA_URL as string);
const MEDIA_URL: string = (env.MEDIA_URL as string);

export interface ProductDetailPage2Props {
    className?: string;
}

const ProductDetailPage2: FC<ProductDetailPage2Props> = ({
                                                             className = "",
                                                         }) => {
    const { t } = useTranslation();
    const {status} = PRODUCTS[0];
    const [tax, setTax] = useState(0.19);
    const {connected, user, afterLogin} = useContext(AuthContext);
    const [variants, setVariants] = useState<ProductVariant[]>([]);
    const [currentVariant, setCurrentVariant] = useState<ProductVariant[]>();
    const [articleImages, setArticleImages] = useState<string[]>([]);
    const [articleVariants, setArticleVariants] = useState<any[]>([]);
    const [featureElements, setFeatureElements] = useState<any[]>([]);
    const [selectedFeatureValue, setselectedFeatureValue] = useState<any[]>([]);
    const [currentFeatureValues, setCurrentFeatureValues] = useState<any[]>([]);
    const [renderList, setRenderList] = useState<any[]>([]);
    const [price, setPrice] = useState<number>(0);
    const [variantQuantity, setVariantQuantity] = useState<number>(1);
    const [reductionPrice, setReductionPrice] = useState<number>(0);
    const [reductionType, setReductionType] = useState<string>('');
    const [variantActive, setVariantActive] = React.useState(0);
    const [qualitySelected, setQualitySelected] = React.useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const [available, setAvailable] = useState(true);
    const [isOpenModalViewAllReviews, setIsOpenModalViewAllReviews] = useState(false);
    const [openFocusIndex, setOpenFocusIndex] = useState(0);
    const {carts, afterAddToCart} = useContext(CartContext);
    const [isLoading, setLoading] = useState(false);

    const handleOpenModal = (index: number, img: string) => {
        if (img) {
            setIsOpen(true);
            setOpenFocusIndex(index);
        }
    };

    const handleCloseModal = () => setIsOpen(false);

    const [article, setArticle] = useState<any>(null);
    const [selectedVariant, setSelectedVariant] = useState<any>(null);
    const { admin } = useContext(AuthContext);

    const fetchVariant = () => {
        setQualitySelected(1);
        for (const variant of articleVariants) {
            const feature_values = variant.feature_values;
            let average = 0;

            for (const item of feature_values) {
                for (const elt of selectedFeatureValue) {
                    if (JSON.stringify(item) === JSON.stringify(elt)) {
                        average++;
                    }
                }
            }

            if (average === renderList.length) {

                setAvailable(false);
                setCurrentVariant(variant);
                setSelectedVariant(variant);
                setCurrentFeatureValues(variant.feature_values);
                setPrice(variant.price);
                setReductionPrice(variant.reduction_price);
                setReductionType(variant.reduction_type);
                setVariantQuantity(variant.quantity);
                //updateSelectedQuantity(variant.quantity);

                if ((variant.medias).length >= 1) {
                    let tabImg: string[] = [];
                    for (const item of variant.medias) {
                        const img = MEDIA_URL + item.path;
                        tabImg.push(img);
                    }
                    setArticleImages(tabImg);
                    break;
                }
                return;
            } else {
                let tabImg: string[] = [];
                for (const item of article.medias) {
                    const img = MEDIA_URL + item.path;
                    tabImg.push(img);
                }
                setArticleImages(tabImg);
                setPrice(article.price);
                setReductionPrice(article.reduction_price);
                setAvailable(true);
            }
        }
    }

    const setDefaultFeature = (e: any) => {

        let feature_name = e.target.getAttribute("id");
        let name = e.target.getAttribute("data-item");

        const index2 = selectedFeatureValue.findIndex(object => object.feature_name === feature_name);

        if (index2 !== -1) {
            selectedFeatureValue[index2].name = name;
        } else {
            selectedFeatureValue.push({feature_name: feature_name, name: name})
        }
        // console.log({feature_name: feature_name, name: name})

        fetchVariant();
    }

    const showFeatureValue = (feature_name: string) => {
        let result: string[] = [];
        for (const item of featureElements) {
            if (!result.includes(item.name) && item.feature_name === feature_name) {
                result.push(item.name);
            }
        }
        return result;
    }

    const updateSelectedQuantity = (val: number) => {
        if (val > variantQuantity)
            setQualitySelected(variantQuantity);
        else
            setQualitySelected(val);
    }

    useEffect(() => {
        setLoading(true);
        const retrieveArticles = async () => {
            const queryParams = new URLSearchParams(window.location.search);
            let id = queryParams.get('articleId');

            let data: any = await getOneArticle(id);

            if (data.tax > 0) {
                setTax((data.tax) / 100);
            }
            setLoading(false);
            setPrice(data.price);
            // setReductionPrice(reductionAmount(data));
            setReductionPrice(data.reduction_price);
            setReductionType(data.reduction_type);
            setArticleVariants(data.variants);
            setArticle(data);
            setSelectedVariant(data.variants[0]);
            setCurrentFeatureValues(data.variants[0].feature_values)
            for (const item of data.medias) {
                const img = MEDIA_URL + item.path;
                articleImages.push(img);
            }

            for (const variant of data.variants) {
                const feature_values = variant.feature_values;

                for (const item of feature_values) {
                    if ((item.name).length > 3) {
                        const value2 = {
                            id: item.id,
                            name: item.name,
                            thumbnail: productVariantImg6,
                            featuredImage: productImgs[0]
                        }

                        const index = variants.findIndex(object => object.id === value2.id);

                        if (index === -1) {
                            variants.push(value2);
                        }
                    }
                }
            }

            for (const variant of data.variants) {
                const feature_values = variant.feature_values;

                for (const item of feature_values) {

                    const index = featureElements.findIndex(object => object.name === item.name);

                    if (index === -1) {
                        featureElements.push(item);
                    }
                }

                //console.log(feature_values);
                for (const item of feature_values) {

                    const index = renderList.findIndex(object => object.feature_name === item.feature_name);

                    if (index === -1) {
                        const clone = {...item}
                        renderList.push(clone);
                        //setRenderList([...renderList, clone]);
                    }
                }

                //setRenderList(tab)
            }

            // console.log(featureElements);
            // console.log(renderList);
            await setArticle(data)
        }
        retrieveArticles();
    }, []);
    //

    const item = {
        name: "Description",
        content:
            "Fashion is a form of self-expression and autonomy at a particular period and place and in a specific context, of clothing, footwear, lifestyle, accessories, makeup, hairstyle, and body posture.",
    };

    const renderVariants = () => {
        // if (variants.length < 1) {
        //     return null;
        // }

        return (
            <div>
                {renderList?.map((featureElement, index) => {
                        // const isActive = variants[variantActive].name === variant.name;
                        // console.log('test feature', featureElement);

                        const featureValues = showFeatureValue(featureElement.feature_name);
                        // console.log("les features sont là")
                        // console.log(featureElement)
                        const indexItem = selectedFeatureValue.findIndex(object => object.feature_name === featureElement.feature_name);
                        return (
                            <div>
                                <Disclosure key={index} defaultOpen={index < 1}>
                                    {({open}) => (
                                        <>
                                            <Disclosure.Button
                                                className="flex items-center justify-between w-full px-4 py-2 font-medium text-left dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 ">
                                                <label htmlFor="">
                                                <span className="text-sm font-medium">
                                                    {featureElement.feature_name}:
                                                    <span className="ml-1 uppercase font-semibold">
                                                        {/*{featureElement.name}*/}
                                                        {selectedFeatureValue[indexItem]?.name}
                                                    </span>
                                                </span>
                                                </label>
                                                {!open ? (
                                                    <PlusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400"/>
                                                ) : (
                                                    <MinusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400"/>
                                                )}
                                            </Disclosure.Button>
                                            <Disclosure.Panel>
                                                <div className="grid grid-cols-4 gap-2 mt-3">
                                                    {featureValues.map((item) => {
                                                        //if(available){
                                                        const index = selectedFeatureValue.findIndex(object => (object.feature_name === featureElement.feature_name && object.name === item));
                                                        const isActive = index >= 0;
                                                        //  }

                                                        //const isActive = true;
                                                        return (
                                                            <div
                                                                key={item}
                                                                className={`relative h-10 sm:h-11 rounded-2xl border flex items-center justify-center 
                                                                    text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0  ${isActive
                                                                && "bg-primary-6000 border-primary-6000 text-white hover:bg-primary-6000"
                                                                    //&& `bg-primary-6000 border-primary-6000 ${'text-'+variant.name} hover:bg-primary-6000`
                                                                    // : "border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700"
                                                                }`}


                                                                id={featureElement.feature_name}
                                                                data-item={item}

                                                                onClick={(e) => {
                                                                    setVariantActive(index)
                                                                    setDefaultFeature(e);
                                                                    // fetchVariant(e)
                                                                }}
                                                            >
                                                                {(item.length > 6) ? (item).substring(0,6)+'...' : item}
                                                            </div>
                                                        )
                                                    })}

                                                </div>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            </div>
                        )
                    }
                )}
            </div>
        );
    };

    const showAlert = (type: any, message: string) => {
        toast.custom(
            (t) => (
                <CustomAlert
                    show={t.visible}
                    type={type}
                    message={message}
                />
                // <CustomAlertBetter
                //       show={t.visible}
                //       productImage={MEDIA_URL + '1672894739.capone.jpg'}
                //       message={message}
                //     />
            ),
            {position: "top-right", id: "nc-product-notify", duration: 3000}
        );
    };

    const notifyAddTocart = async () => {
        //console.log(selectedVariant);
        let realPrice = computePrice(selectedVariant);
        //console.log("le vraie prix " + realPrice);

        if (connected) {
            try {
                await addToCart(user?.id, selectedVariant?.id, realPrice, qualitySelected);
                afterAddToCart();
                toast.custom(
                    (t) => (
                        <NotifyAddTocart
                            productImage={articleImages[0]}
                            qualitySelected={qualitySelected}
                            name={article?.name}
                            //price={computePrice(price)}
                            //price={(selectedVariant && !available) ? computePrice(selectedVariant)  : computePrice(article)}
                            price={selectedVariant.price - reductionAmount(selectedVariant)}
                            show={t.visible}
                            featureValues={currentFeatureValues}
                        />
                    ),
                    {position: "top-right", id: "nc-product-notify", duration: 2000}
                );
                setQualitySelected(1);
            } catch (error: any) {
                showAlert("error", error.message)
                //console.log(error.message);
            }
        } else {
            showAlert("error", "You must be connected before add articles to cart !");
        }
    };

    const notifyErorAddTocart = async () => {
        //console.log(selectedVariant);
        if (connected) {
            showAlert("info", "This product is not available with these characteristics!");
        } else {
            showAlert("error", "You must be connected before add articles to cart !");
        }
    };

    const renderStatus = () => {
        if (!status) {
            return null;
        }
        const CLASSES =
            "text-sm flex items-center text-slate-700 text-slate-900 dark:text-slate-300";
        if (status === "New in") {
            return (
                <div className={CLASSES}>
                    <SparklesIcon className="w-3.5 h-3.5"/>
                    <span className="ml-1 leading-none">{status}</span>
                </div>
            );
        }
        if (status === "50% Discount") {
            return (
                <div className={CLASSES}>
                    <IconDiscount className="w-3.5 h-3.5"/>
                    <span className="ml-1 leading-none">{status}</span>
                </div>
            );
        }
        if (status === "Sold Out") {
            return (
                <div className={CLASSES}>
                    <NoSymbolIcon className="w-3.5 h-3.5"/>
                    <span className="ml-1 leading-none">{status}</span>
                </div>
            );
        }
        if (status === "limited edition") {
            return (
                <div className={CLASSES}>
                    <ClockIcon className="w-3.5 h-3.5"/>
                    <span className="ml-1 leading-none">{status}</span>
                </div>
            );
        }
        return null;
    };

    const renderSectionSidebar = () => {
        return (
            <div className="listingSectionSidebar__wrap lg:shadow-lg">
                <div className="space-y-7 lg:space-y-8">
                    {/* PRICE */}
                    <div className="">
                        {/* ---------- 1 HEADING ----------  */}
                        <div className="flex items-center justify-between space-x-5">
                            <div className="flex text-2xl font-semibold">
                                <Prices
                                    contentClass="py-1 px-2 md:py-1.5 md:px-3 text-lg font-semibold"
                                    price={parseInt(price.toFixed(2))}
                                    reductionPrice={reductionPrice}
                                    reductionType={reductionType}
                                />
                            </div>

                            {/*<a*/}
                            {/*    href="#reviews"*/}
                            {/*    className="flex items-center text-sm font-medium"*/}
                            {/*>*/}
                            {/*<div className="">*/}
                            {/*    <StarIcon className="w-5 h-5 pb-[1px] text-orange-400"/>*/}
                            {/*</div>*/}
                            {/*<span className="ml-1.5 flex">*/}
                            {/*<span>4.9 </span>*/}
                            {/*<span className="mx-1.5">·</span>*/}
                            {/*<span className="text-primary-400 primary:text-slate-400 underline"  onClick={resetSelection} style={{cursor: 'pointer'}}>*/}
                            {/* Reset*/}
                            {/*</span>*/}

                            {/*<span className="text-slate-700 dark:text-slate-400 underline">*/}
                            {/*    142 reviews*/}
                            {/*  </span>*/}
                            {/*</span>*/}
                            {/*</a>*/}
                        </div>

                        {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
                        <div className="mt-6 space-y-7 lg:space-y-8">
                            <div className="">{renderVariants()}</div>
                            {/*<div className="">{renderSize()}</div>*/}
                        </div>
                    </div>
                    {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
                    <div className="flex space-x-3.5">
                        <div
                            className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
                            <NcInputNumber
                                defaultValue={qualitySelected}
                                onChange={updateSelectedQuantity}
                                max={variantQuantity}
                                available={available}
                            />
                        </div>
                        <ButtonPrimary
                            className="flex-1 flex-shrink-0"
                            disabled={available}
                            onClick={available ? notifyErorAddTocart : notifyAddTocart}
                        >
                            <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5"/>
                            {available ? <span className="ml-3" onClick={notifyErorAddTocart}>{t("add_to_cart")}</span> :
                                <span className="ml-3" >{t("add_to_cart")}</span>}
                        </ButtonPrimary>
                    </div>

                    {/* SUM */}
                    <div className="hidden sm:flex flex-col space-y-4 ">
                        <div className="space-y-2.5">
                            <div className="flex justify-between text-slate-600 dark:text-slate-300">
                                <span className="flex">
                                    <span>{`${(selectedVariant && !available) ? computePrice(selectedVariant) : computePrice(article)}€  `}</span>
                                    <span className="mx-2">x</span>
                                    <span>{`${qualitySelected} `}</span>
                                </span>
                                <span>{`${(selectedVariant && !available) ? computePrice(selectedVariant) * qualitySelected : computePrice(article) * qualitySelected}€`}</span>
                            </div>
                            <div className="flex justify-between text-slate-600 dark:text-slate-300">
                                {/*<span>Tax include ({(tax*100).toFixed(2)}%)</span>*/}
                                {/*<span>{`$${((price * qualitySelected)*tax).toFixed(2)}`}</span>*/}
                            </div>
                        </div>
                        <div className="border-b border-slate-200 dark:border-slate-700"></div>
                        <div className="flex justify-between font-semibold">
                            <span>{t("total")}</span>
                            <span>{`${(selectedVariant && !available) ? computePrice(selectedVariant) * qualitySelected : computePrice(article) * qualitySelected}€`}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderSection1 = () => {
        return (
            <div className="listingSection__wrap !space-y-6">
                <div>
                    <h2 className="text-2xl md:text-3xl font-semibold">
                        {article?.name}
                    </h2>
                    {/*    <div className="flex items-center mt-4 sm:mt-5">*/}
                    {/*        <a*/}
                    {/*            href="#reviews"*/}
                    {/*            className="hidden sm:flex items-center text-sm font-medium "*/}
                    {/*        >*/}
                    {/*            <div className="">*/}
                    {/*                <StarIcon className="w-5 h-5 pb-[1px] text-slate-800 dark:text-slate-200"/>*/}
                    {/*            </div>*/}
                    {/*            <span className="ml-1.5">*/}
                    {/*<span>4.9</span>*/}
                    {/*<span className="mx-1.5">·</span>*/}
                    {/*<span className="text-slate-700 dark:text-slate-400 underline">*/}
                    {/*142 reviews*/}
                    {/*</span>*/}
                    {/*</span>*/}
                    {/*        </a>*/}
                    {/*        <span className="hidden sm:block mx-2.5">·</span>*/}
                    {/*        /!*{renderStatus()}*!/*/}

                    {/*        <div className="ml-auto">*/}
                    {/*            <LikeSaveBtns/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                </div>
                {/*  */}
                <div className="block lg:hidden">{renderSectionSidebar()}</div>

                {/*  */}
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

                {/*  */}
                {article != null ? <AccordionInfo articleData={article}
                                                  panelClassName="p-4 pt-3.5 text-slate-600 text-base dark:text-slate-300 leading-7"/> : null}
                <div className="flex items-center mb-0.5">
                    <a href={`mailto:${admin?.email}?subject=${article?.name}&body=${article?.description}`}
                       target="_blank">
                        <div className="flex-shrink-0 w-5 cursor-pointer">
                            <img src={gmail} alt=""/>
                        </div>
                    </a>
                    <a href={`https://wa.me/${admin?.phone}?text=This is WhatsApp sharing example using link`} target="_blank">
                        <div className="flex-shrink-0 w-5 cursor-pointer ml-3">
                            <img src={whatsapp} alt=""/>
                        </div>
                    </a>
                </div>
            </div>
        );
    };

    const renderSection2 = () => {
        return (
            <div className="listingSection__wrap !border-b-0 !pb-0">
                <h2 className="text-2xl font-semibold">Product details</h2>
                {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div> */}
                <div className="prose prose-sm sm:prose dark:prose-invert sm:max-w-4xl">
                    <p>
                        The patented eighteen-inch hardwood Arrowhead deck --- finely
                        mortised in, makes this the strongest and most rigid canoe ever
                        built. You cannot buy a canoe that will afford greater satisfaction.
                    </p>
                    <p>
                        The St. Louis Meramec Canoe Company was founded by Alfred Wickett in
                        1922. Wickett had previously worked for the Old Town Canoe Co from
                        1900 to 1914. Manufacturing of the classic wooden canoes in Valley
                        Park, Missouri ceased in 1978.
                    </p>
                    <ul>
                        <li>Regular fit, mid-weight t-shirt</li>
                        <li>Natural color, 100% premium combed organic cotton</li>
                        <li>
                            Quality cotton grown without the use of herbicides or pesticides -
                            GOTS certified
                        </li>
                        <li>Soft touch water based printed in the USA</li>
                    </ul>
                </div>
                {/* ---------- 6 ----------  */}
                <Policy/>
            </div>
        );
    };

    const renderReviews = () => {
        return (
            <div id="reviews" className="scroll-mt-[150px]">
                {/* HEADING */}
                <h2 className="text-2xl font-semibold flex items-center">
                    <StarIcon className="w-7 h-7 mb-0.5"/>
                    <span className="ml-1.5"> 4,87 · 142 Reviews</span>
                </h2>

                {/* comment */}
                <div className="mt-10">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-y-11 gap-x-28">
                        <ReviewItem/>
                        <ReviewItem
                            data={{
                                comment: `I love the charcoal heavyweight hoodie. Still looks new after plenty of washes. 
                  If you’re unsure which hoodie to pick.`,
                                date: "December 22, 2021",
                                name: "Stiven Hokinhs",
                                starPoint: 5,
                            }}
                        />
                        <ReviewItem
                            data={{
                                comment: `The quality and sizing mentioned were accurate and really happy with the purchase. Such a cozy and comfortable hoodie. 
                Now that it’s colder, my husband wears his all the time. I wear hoodies all the time. `,
                                date: "August 15, 2022",
                                name: "Gropishta keo",
                                starPoint: 5,
                            }}
                        />
                        <ReviewItem
                            data={{
                                comment: `Before buying this, I didn't really know how I would tell a "high quality" sweatshirt, but after opening, I was very impressed. 
                The material is super soft and comfortable and the sweatshirt also has a good weight to it.`,
                                date: "December 12, 2022",
                                name: "Dahon Stiven",
                                starPoint: 5,
                            }}
                        />
                    </div>

                    <ButtonSecondary
                        onClick={() => setIsOpenModalViewAllReviews(true)}
                        className="mt-10 border border-slate-300 dark:border-slate-700 "
                    >
                        Show me all 142 reviews
                    </ButtonSecondary>
                </div>
            </div>
        );
    };

    return (
        <>

            <div
                className={`ListingDetailPage nc-ProductDetailPage2 ${className}`}
                data-nc-id="ProductDetailPage2"
            >
                {/* SINGLE HEADER */}
                    <header className="container mt-8 sm:mt-10">
                        <div className="relative ">
                            <div className="grid grid-cols-2 md:grid-cols-3 gap-3 lg:gap-6">
                                <div
                                    className="col-span-2 md:col-span-1 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                                    onClick={() => handleOpenModal(0, articleImages[0])}
                                >
                                    <NcImage
                                        containerClassName="aspect-w-3 aspect-h-4 md:absolute md:inset-0"
                                        className="object-cover w-full h-full rounded-md sm:rounded-xl"
                                        src={articleImages[0]}
                                    />
                                    <div
                                        className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-40 transition-opacity"></div>
                                </div>

                                {/*  */}
                                <div
                                    className="col-span-1 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden"

                                >
                                    <NcImage
                                        containerClassName="absolute inset-0"
                                        className="object-cover w-full h-full rounded-md sm:rounded-xl"
                                        src={articleImages[1] ? articleImages[1] : "dodoshopfont.jpeg"}
                                    />
                                    {articleImages[1] &&
                                        <div
                                            className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-40 transition-opacity cursor-pointer"
                                            onClick={() => handleOpenModal(1, articleImages[1])}></div>}
                                </div>

                                {/*  */}
                                {[articleImages[2], articleImages[3]].map((item, index) => (
                                    <div
                                        key={index}
                                        className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 2 ? "block" : ""
                                        }`}
                                    >
                                        <NcImage
                                            containerClassName="aspect-w-6 aspect-h-5 lg:aspect-h-4"
                                            className="object-cover w-full h-full rounded-md sm:rounded-xl "
                                            src={item || "dodoshopfont.jpeg"}
                                        />

                                        {/* OVERLAY */}
                                        {item && <div
                                            className="absolute inset-0 bg-slate-900 bg-opacity-20 opacity-0 hover:opacity-60 transition-opacity cursor-pointer"
                                            onClick={() => handleOpenModal(index + 2, item)}
                                        />}
                                    </div>
                                ))}
                            </div>
                            <div
                                className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-white text-slate-500 cursor-pointer hover:bg-slate-200 z-10"
                                onClick={() => handleOpenModal(0, articleImages[0])}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={1.5}
                                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                                    />
                                </svg>
                                <span className="ml-2 text-neutral-800 text-sm font-medium">
                                        {t("show_all_photos")}
                                    </span>
                            </div>
                        </div>
                    </header>
                {isLoading ?
                    <div style={{
                        position: 'absolute',
                        height: '100px',
                        width: '100px',
                        top: '50%',
                        left: '50%',
                        marginLeft: '-50px',
                        marginTop: '-50px',
                        backgroundSize:' 100%'}}>
                        <ReactLoading width={100} type={"spin"} color="yellow" />
                    </div>
                    :
                    <>
                        {/* MODAL PHOTOS */}
                        <ModalPhotos
                            imgs={articleImages}
                            isOpen={isOpen}
                            onClose={handleCloseModal}
                            initFocus={openFocusIndex}
                            uniqueClassName="nc-ProductDetailPage2__modalPhotos"
                        />
                        {/* MAIn */}
                        <main className="container relative z-10 mt-9 sm:mt-11 flex ">
                            {/* CONTENT */}
                            <div className="w-full lg:w-3/5 xl:w-2/3 space-y-10 lg:pr-14 lg:space-y-14">
                                {renderSection1()}
                                {/*{renderSection2()}*/}
                            </div>

                            {/* SIDEBAR */}
                            <div className="flex-grow">
                                <div className="hidden lg:block sticky top-28">
                                    {renderSectionSidebar()}
                                </div>
                            </div>
                        </main>
                    </>}

                {/* OTHER SECTION */}
                {/*<div className="container pb-24 lg:pb-28 pt-14 space-y-14">*/}
                {/*  <hr className="border-slate-200 dark:border-slate-700" />*/}

                {/*  {renderReviews()}*/}

                {/*  <hr className="border-slate-200 dark:border-slate-700" />*/}

                {/*  <SectionSliderProductCard*/}
                {/*    heading="Customers also purchased"*/}
                {/*    subHeading=""*/}
                {/*    headingFontClassName="text-2xl font-semibold"*/}
                {/*    headingClassName="mb-10 text-neutral-900 dark:text-neutral-50"*/}
                {/*  />*/}
                {/*</div>*/}

                {/* MODAL VIEW ALL REVIEW */}
                <ModalViewAllReviews
                    show={isOpenModalViewAllReviews}
                    onCloseModalViewAllReviews={() => setIsOpenModalViewAllReviews(false)}
                />
            </div>

        </>
    );
};

export default ProductDetailPage2;
