import axios from "axios";

export const getTest = (test: any) => {
    // Change test object
    return test;
};

export const connection = (data: any) => {
    let loginFormData = new FormData();

    loginFormData.append("email", data.email);
    loginFormData.append("password", data.password);
    loginFormData.append("remember", data.remember);

    return new Promise((resolve, reject) => {
      axios
        .post('/login', loginFormData)
        .then( (response: any) => {
            if(response.data.data.remember){
              localStorage.setItem("access_token", response.data.data.token);
              localStorage.setItem("user_id", response.data.data.user_id);
            }else{
              sessionStorage.setItem("access_token", response.data.data.token);
              sessionStorage.setItem("user_id", response.data.data.user_id);
            }

            resolve(response);
        })
        .catch( (error: any) => {
          reject(error.response);
        });
    });
  };

export const resent = (email: string) => {
    let emailFormData = new FormData();

    emailFormData.append("email", email);
  
    return new Promise((resolve, reject) => {
      axios
        .post('/email/verify/resend', emailFormData)
        .then( (response: any) => {

          resolve(response.data);

        })
        .catch( (error: any) => {
          reject(error.response.data);
        });
    });
  };

  export const registration = (data: any) => {

    let registerFormData = new FormData();

    registerFormData.append("username", data.username);
    registerFormData.append("first_name", "");
    registerFormData.append("last_name", "");
    registerFormData.append("email", data.email);
    registerFormData.append("password", data.password);
    registerFormData.append("password_confirmation", data.password_confirmation);
    registerFormData.append("question", "");
    registerFormData.append("answer", "");
  
    return new Promise((resolve, reject) => {
      axios
        .post('/register', registerFormData)
        .then( (response: any) => {
          console.log(response);
          resolve(response);
        })
        .catch( (error: any) => {
          reject(error.response.data);
        });
    });
  };

  export function getLoginUrl(provider: string) {

    return new Promise((resolve, reject) => {
        axios
            .get(`/login/redirect/${provider}`)
            .then((response: any) => {
                resolve(response.data.data);
            })
            .catch((error: any) => {
                reject(error.response.data);
            });
    });
}

export function socialCallback(search: string, provider: string) {
  console.log(search)
  return new Promise((resolve, reject) => {
      axios
          .get(`/login/${provider}/callback${search}`)
          .then((response: any) => {
            console.log(response.data.data)
              if(response.data.data.remember){
                
                localStorage.setItem("access_token", response.data.data.token);
                localStorage.setItem("user_id", response.data.data.user_id);
              }else{
                sessionStorage.setItem("access_token", response.data.data.token);
                sessionStorage.setItem("user_id", response.data.data.user_id);
              }
              resolve(response);
          })
          .catch((error: any) => {
              console.log(error.response.data)
              reject(error.response.data);
          });
  });
}